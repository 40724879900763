<template>
    <v-container class="contenedor-Stools">
            <v-row>
            <v-col cols="12" md="3">
                    <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        locale="ES"
                        min-width="auto"
                        >
                        <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                            v-model="dates"
                            multiple
                            clearable
                            chips
                            small-chips
                            label="Rango de fechas"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            rounded
                            ></v-combobox>
                        </template>
                        <v-date-picker
                        locale="ES"
                            v-model="dates"
                            multiple
                            no-title
                            range
                            clearable
                            scrollable
                            @change="save"
                        >
                        </v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
    </v-container>
</template>

<script>

export default {
    components:{
    },
    name:"ToolBarNavDateFilter",
    props: [],
    data() {
        return {
            // dates:[],
            dates:null,

        }
    },
    created() {
        this.dates = this.$store.state.fechasPagos
    },
    mounted() {
        this.$emit("toggle", this.dates);
        
    },
    methods: {
        blur(){
            this.$emit("toggle", 'blur');
        },
        save() {
            this.$store.state.fechasPagos = this.dates;
            this.$emit("toggle", this.dates);
        }
    }

}
</script>

<style>
.contenedor-Stools{
    min-width: 100%;
    margin: 0;
    width: 100%;
    display: flex;
    padding: 5px;
    border: 1px  #8884 solid ;
    z-index: 100;
}

</style>